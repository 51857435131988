/* eslint-disable indent */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

import _ from 'lodash';
import { ReportHeader } from './common';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (Products) => {
  const productList = _.map(Products, (product, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: [
        { text: `${product?.name}\n` },
        { text: `${product?.about || ''}`, fontSize: 8 },
      ],
      alignment: 'left',
    },
    { text: `${product?.quantity} ${product?.unit}`, alignment: 'center' },
    {
      text: `${product?.price
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
    {
      text: `${product?.discount
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
    {
      text: `${(product?.price * product?.quantity - product?.discount)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
  ]);

  return productList;
};

const QtReport = async (data, information, me, checked) => {
  console.log('me', me);
  console.log('data  inQt', data);
  const THBText = require('thai-baht-text');
  const sumPrice = _.sumBy(
    data.product,
    (each) => each?.price * each?.quantity
  );
  const sumDiscount = _.sumBy(data?.product, (each) =>
    parseInt(each?.discount)
  );
  const sumAllPrice = (sumPrice - sumDiscount)?.toFixed(2);
  console.log('sumAllPrice', sumAllPrice);

  const taxPrice = (statusTax) => {
    if (!statusTax) {
      return 0?.toFixed(2);
    }
    return (sumAllPrice * 0.03)?.toFixed(2);
  };
  console.log('taxPrice', taxPrice(true));

  // (sumPrice * 0.03).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  const taxAll = (statusTax) => {
    return (sumAllPrice - taxPrice(statusTax))?.toFixed(2);
  };
  console.log('tax all', taxAll(true));
  const taxUniversity = (statusTaxUni) => {
    if (!statusTaxUni) {
      return 0?.toFixed(2);
    }
    return (sumAllPrice * 0.01)?.toFixed(2);
  };
  const taxAllUni = (statusTaxUni) => {
    return (sumAllPrice - taxUniversity(statusTaxUni))?.toFixed(2);
  };

  const genItemBody = genBody(data.product);

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 270, 20, 250],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: `${data.prefix}${data.running_number}_${data?.customer?.businessname}`,
    },
    header: [
      ReportHeader(information),
      {
        alignment: 'center',
        margin: [20, -2, 20, 4],
        table: {
          alignment: 'center',

          widths: ['49.9%', '12.8%', '13%', '*'],
          body: [
            [
              {
                fillColor: '#4297ff',
                rowSpan: 2,
                text: 'ใบเสนอราคา/Quotation',
                margin: 10,
                style: 'header1',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: 'เลขที่' },
              {
                text: `${data.prefix}${data.running_number}`,
                alignment: 'left',
              },
            ],
            [
              '',
              {
                text: '',
                border: [false, false, false, false],
              },
              { text: 'วันที่', margin: [0, 4, 0, 0] },
              {
                text: `${dayjs(data.date).format('DD MMMM BBBB')}`,
                alignment: 'left',

                margin: [0, 4, 0, 0],
              },
            ],
          ],
        },
      },
      {
        margin: [20, -2, 20, 0],
        table: {
          alignment: '',

          widths: ['15%', '34%', '13%', '13%', '*'],
          body: [
            [
              {
                text: 'ชื่อลูกค้า',
                style: 'body',
                border: [true, true, false, false],
              },
              {
                text: `${data?.customer?.businessname || '-'}`,
                border: [false, true, true, false],
                style: 'body',
              },
              { text: '', border: [false, false, false, false] },
              // {
              //   text: '',
              //   style: 'body',
              //   border: [true, true, false, false],
              // },
              // {
              //   text: '',
              //   border: [false, true, true, false],
              // },
              {
                text: 'การชำระเงิน',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.payment || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'เลขที่ผู้เสียภาษี ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.customer?.TaxId || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              }, // texid
              { text: '', border: [false, false, false, false] },
              {
                text: 'จำนวนวันเครดิต',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.credit || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'ชื่อผู้ติดต่อ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.customer?.name || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: '',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: '',
                style: 'body',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.customer?.address || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: '',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: '',
                style: 'body',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'เบอร์โทร',
                style: 'body',
                border: [true, false, false, true],
              },
              {
                text: `${data?.customer?.tel || '-'}`,
                style: 'body',
                border: [false, false, true, true],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [true, false, false, true] },
              { text: '', border: [false, false, true, true] },
            ],
            // ...order
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['5%', '45.01%', '13%', '13.33%', '10.33%', '13.33%'],
          body: [
            [
              {
                text: 'ลำดับ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการสินค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ราคาต่อหน่วย',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ส่วนลด',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวนเงิน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],
    pageBreakBefore(
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) {
      return (
        currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
      );
    },

    footer: [
      {
        margin: [20, -2, 20, 0],
        table: {
          alignment: '',

          widths: ['75.33%', '*', '12.33%'],
          body: [
            [
              {
                text: [
                  { text: 'หมายเหตุ\n', bold: true },
                  `${data?.note || '-'}`,
                ],
                rowSpan: 3,

                border: [false, false, false, false],
              },
              {
                text: 'รวมเงิน',
                border: [false, false, false, false],
              },
              {
                text: `${sumPrice
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                border: [false, false, false, false],
                style: 'number',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'ส่วนลดการค้า',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${
                  sumDiscount
                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                style: 'number',
                border: [false, false, false, false],
              },
            ],

            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'เงินหลังหักส่วนลด',
                border: [false, false, false, false],
              },
              {
                text: `${
                  sumAllPrice?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                border: [false, false, false, false],
                style: 'number',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                // text: 'ภาษีหัก ณ ที่จ่าย',
                // border: [false, false, false, false],
                text: 'หัก ณ ที่จ่าย 3%',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: taxPrice(data?.customer?.status)?.replace(
                  /\d(?=(\d{3})+\.)/g,
                  '$&,'
                ),
                border: [false, false, false, false],
                bold: true,
                style: 'number',
              },
              // {
              //   // text: taxPrice(data?.statusTax).replace(
              //   //   /\d(?=(\d{3})+\.)/g,
              //   //   '$&,'
              //   // ),
              //   // border: [false, false, false, false],
              //   // style: 'number',

              //   text: taxPrice(data?.customer?.status),
              //   border: [false, false, false, false],
              //   bold: true,
              //   style: 'number',
              // },
            ],
            [
              {
                fillColor: '#4297ff',
                text: `${THBText(taxAll(data?.statusTax))}`,
                border: [false, false, false, false],
                alignment: 'center',
                bold: true,
              },
              {
                text: 'จำนวนเงินทั้งสิ้น',
                border: [false, false, false, false],
              },
              {
                fillColor: '#4297ff',
                text: taxAll(data?.statusTax)?.replace(
                  /\d(?=(\d{3})+\.)/g,
                  '$&,'
                ),
                border: [false, false, false, false],
                bold: true,
                style: 'number',
              },
            ],
          ],
        },
      },
      {
        margin: [20, 2, 20, 0],
        alignment: 'center',
        table: {
          alignment: '',
          widths: ['33.33%', '*', '33.33%'],
          body: [
            [
              checked
                ? {
                    image: 'sign',
                    fit: [50, 50],

                    alignment: 'center',
                    border: [true, true, true, false],
                  }
                : {
                    text: [{ text: '....................................\n' }],
                    margin: [0, 40, 0, 0],
                    alignment: 'center',
                    border: [true, true, true, false],
                  },

              {
                text: [{ text: '' }],
                margin: [0, 0, 0, 0],
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: '....................................\n',
                margin: [0, 40, 0, 0],
                alignment: 'center',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: [
                  { text: 'ผู้เสนอราคา\n' },
                  {
                    text: ` วันที่ ${dayjs(data.date).format(
                      'DD MMMM BBBB'
                    )} \n `,
                  },
                ],
                alignment: 'center',
                border: [true, false, true, true], // left top right button
              },
              {
                text: 'ตราบริษัท',
                alignment: 'center',

                border: [true, false, true, true],
              },
              {
                text: [
                  { text: 'ผู้มีอำนาจลงนาม/Authorized Signature \n' },
                  { text: 'วันที่/date............................\n' },
                ],
                alignment: 'center',
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'right',
      },
      header1: {
        fontSize: 14,
        bold: true,
      },
      body: {
        fontSize: 9,
        alignment: 'left',
        bold: true,
      },
      body1: {
        alignment: 'right',
        fontSize: 10,
        bold: true,
      },
      body2: {
        alignment: 'right',
        fontSize: 8,
      },
      number: {
        alignment: 'right',
        fontSize: 10,
        bold: true,
      },
    },
    images: {
      logo: information?.image?.url || '',
      sign: me?.userData?.signature?.url || '',
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default QtReport;
