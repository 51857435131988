/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Link } from 'react-router-dom';

import Paper from '@mui/material/Paper';

import _ from 'lodash';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Card, CardContent } from '@mui/material';
// import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
// import { useForm } from 'react-hook-form';
// import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import SalaryYearReport from 'components/Report/salaryYearReport';
// import buddhistEra from 'dayjs/plugin/buddhistEra';

import dayjs from 'dayjs';
import { ViewTitle } from 'components/ViewTitle';
function SalaryDoc({ title, subtitle }) {
  const dispatch = useDispatch();
  const salarys = useSelector((state) => state.Salary);
  const information = useSelector((state) => state.information);
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);
  const [date, setDate] = React.useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = React.useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const [year, setYear] = React.useState(false);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    dispatch(action.resetSaraly());

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      action.getAllSalary({
        findName,
        page,
        size,
        startDate: dayjs(date).toISOString(),
        endDate: dayjs(endDate).toISOString(),
        status_month: true,
      })
    );
    return () => {};
  }, [findName, page, size, date, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleSalaryReport = (data) => {
    SalaryYearReport(data, date, endDate, information);
    console.log('datasalary', data);
    console.log('information?.arr?.[0]', information);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const revenue = (datasalary, dataOT, dataExtra) => {
    return datasalary + dataOT + dataExtra;
  };
  const expenses = (dataSSO, dataTax, dataEtc, salary) => {
    return dataSSO + dataTax + dataEtc;
  };

  if (salarys?.isloading) {
    return <div>loading</div>;
  }
  console.log('SAlary', salarys);

  if (!salarys?.isloading) {
    return (
      <div>
        {renderTitle()}
        <Card className="mb-2">
          <CardContent>
            <h1 className="pb-2">รายงานเงินเดือนประจำปี</h1>
            <div>
              <div className="flex justify-between">
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="เริ่มต้น"
                      views={['year', 'month']}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>{' '}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="สิ้นสุด"
                      views={['year', 'month']}
                      value={endDate}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>{' '}
                </div>

                <div>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleSalaryReport(salarys)}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {''}
                    <p className="font-bold">รหัสพนักงาน</p>
                  </TableCell>

                  <TableCell>
                    {' '}
                    <p className="font-bold">ชื่อ-สกุล</p>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <p className="font-bold">รวมรายรับ</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">รวมหัก</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">รับสุทธิ</p>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.sortBy(salarys?.arr, [
                  (salary) => salary?._id?.employeeId,
                ]).map((salary) => (
                  <TableRow key={salary._id}>
                    <TableCell component="th" scope="row">
                      {salary?._id?.employeeId || '-'}
                    </TableCell>
                    <TableCell>
                      {salary?._id?.name} {salary?._id?.surname}
                    </TableCell>
                    <TableCell>
                      {salary?.salary + salary?.ot + salary?.extra}
                    </TableCell>
                    <TableCell>{salary?.sumSSO + salary?.etc}</TableCell>
                    <TableCell>
                      {revenue(salary?.salary, salary?.ot, salary?.extra) -
                        expenses(salary?.sumSSO, salary?.tax, salary?.etc)}
                    </TableCell>
                    <TableCell />
                    <TableCell align="right" />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={salarys?.total}
            rowsPerPage={size}
            page={page - 1}
          />
        </Paper>
      </div>
    );
  }
  return <h1>404</h1>;
}
export default SalaryDoc;
