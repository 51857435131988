/* eslint-disable react/prop-types */
import { TextField, Autocomplete, Button, Card } from '@mui/material';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { Controller } from 'react-hook-form';
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import dayjs from 'dayjs';
import _ from 'lodash';
import { watch } from 'fs';
// import ModalUploadFile from 'components/Modal/ModalUploadFile';

export default function FormEmployee({
  users,
  fields,
  append,
  remove,
  control,
  hideButton = true,
  hideUpload = true,
  department,
  handleChange,
  date,
  setDate,
  setDatePass,
  level,
  group,
  position,
  handleChangePositon,
  handleChangeLevel,
  handleChangeGroup,
  edit,
  datePass,
  selectTypeemployee,
  subBusiness,
  handlesubBusiness,
  file,
  setFile,
  modalFileOpen,
  setModalFileOpen,
}) {
  dayjs.extend(buddhistEra);
  console.log('group', group);
  const [value, setValue] = React.useState(dayjs('2022-04-17'));
  console.log('  fields  in ', fields);

  const renderAddFile = () => (
    <div>
      <div className="font-bold py-2 ">เอกสารเพิ่มเติม</div>
      <div className=" px-6 ">
        {_?.map(fields, (eachField, index) => (
          <div key={eachField.id} className="my-4">
            <Card>
              <div className=" p-6">
                <div>
                  <Controller
                    name={`certificate[${index}].name`}
                    defaultValue={eachField?.name}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="ชื่อใบรับรอง"
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
                {/* <div className="py-2">
                      <Controller
                        name={`certificate[${index}].date_start`}
                        defaultValue=""
                        control={control}
                        render={({ field }) => {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                {...field}
                                label="วันที่ได้ใบรับรอง"
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          );
                        }}
                      />
                    </div> */}
                {/* <div className="py-2">
                      <Controller
                        name={`certificate[${index}].date_end`}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              {...field}
                              label="วันใบรับรองหมดอายุ"
                              value={field.}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth size="small" />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div> */}
                <div className="py-2">
                  {console.log(' eachField', eachField)}
                  <Controller
                    name={`certificate[${index}].note`}
                    defaultValue={eachField?.note}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        id="outlined-basic"
                        label="หมายเหตุ"
                        size="medium"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div></div>

                <div className=" flex">
                  {/* <Button
                    variant="contained"
                    onClick={() => setModalFileOpen(true)}
                  >
                    อัพโหลดเอกสาร
                  </Button> */}

                  <div>
                    {users?.certificate?.file && (
                      <div className="pl-4">
                        <a href={file?.url} target="_blank">
                          <h1 className="text-blue-600 font-bold ">
                            {users?.certificate?.file?.file_name}
                          </h1>
                        </a>
                      </div>
                    )}
                    {!_.isEmpty(file) && (
                      <div className="pl-4">
                        <h1 className="font-bold">ไฟล์ใหม่</h1>
                        <h1>{users?.certifacate?.file?.[0]?.name}</h1>
                      </div>
                    )}{' '}
                  </div>
                </div>
              </div>
              {hideUpload && (
                <div className="px-6">
                  <Controller
                    name={`certificate[${index}].file`}
                    control={control}
                    render={({ field }) => (
                      <input
                        type="file"
                        onChange={(e) => {
                          field.onChange(e.target.files?.[0]);
                        }}
                      />
                    )}
                  />
                </div>
              )}

              {!hideUpload && (
                <div className="pl-8">
                  <a href={eachField?.file?.url} target="_blank">
                    <h1 className="text-blue-600 font-bold ">
                      {eachField?.file?.file_name}
                    </h1>
                  </a>
                  {/* <div className="py-4 fo-bold">แก้ไขไฟล์ใหม่</div> */}
                  <div className="px-6">
                    <Controller
                      name={`certificate[${index}].file`}
                      control={control}
                      render={({ field }) => (
                        <input
                          type="file"
                          onChange={(e) => {
                            field.onChange(e.target.files?.[0]);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              <div className="text-center  p-2">
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  onClick={() => remove(index)}
                >
                  ลบ
                </Button>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <div>
      <h1 className="font-bold">ข้อมูลส่วนตัว</h1>
      <div className=" py-1 lg:grid grid-cols-2 gap-2">
        <div>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="ชื่อ"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="surname"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="นามสกุล"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="nickname"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="ชื่อเล่น"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          {' '}
          <Controller
            name="personalID"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="เลขบัตรประจำตัวประชาชน"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="tel"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                id="outlined-basic"
                label="เบอร์โทร"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          {' '}
          <Controller
            name="email"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                id="outlined-basic"
                label="Email"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          {' '}
          <Controller
            name="address"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                id="outlined-basic"
                label="ที่อยู่"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="booking"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="เลขบัญชี"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
      </div>
      {edit && (
        <div>
          <div className="font-bold py-2 ">ข้อมูลการทำงาน</div>
          <div className="lg:grid grid-cols-2 gap-2 ">
            <div>
              {' '}
              <Controller
                name="employeeId"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    id="outlined-basic"
                    label="รหัสพนักงาน"
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="วันที่เริ่มทำงาน"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="วันที่ผ่านโปร"
                  value={datePass}
                  onChange={(newValue) => {
                    setDatePass(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="">
              <Controller
                name="subBusiness"
                control={control}
                defaultValue={users?.subBusiness ? users?.subBusiness : ''}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    id="free-solo-demo"
                    freeSolo
                    size="small"
                    options={subBusiness?.arr || []}
                    getOptionLabel={(option) => `${option?.name || '-'}`}
                    onChange={(e, newValue) => handlesubBusiness(newValue)}
                    renderInput={(param) => (
                      <TextField
                        {...param}
                        size="small"
                        label="สังกัด/บริษัทย่อย"
                      />
                    )}
                  />
                )}
              />
            </div>
            <div className="">
              <Controller
                name="group"
                control={control}
                defaultValue={users?.group ? users?.group : ''}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    id="free-solo-demo"
                    freeSolo
                    size="small"
                    options={group?.arr || []}
                    getOptionLabel={(option) => `${option?.name || '-'}`}
                    onChange={(e, newValue) => handleChangeGroup(newValue)}
                    renderInput={(param) => (
                      <TextField {...param} size="small" label="ฝ่าย" />
                    )}
                  />
                )}
              />
            </div>
            <div className="">
              <Controller
                name="department"
                control={control}
                defaultValue={users?.department ? users?.department : null}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    id="free-solo-demo"
                    freeSolo
                    size="small"
                    options={department?.arr || []}
                    getOptionLabel={(option) =>
                      ` แผนก ${option?.name || 'ยังไม่เลือก'}`
                    }
                    onChange={(e, newValue) => field.onChange(newValue)}
                    renderInput={(param) => (
                      <TextField
                        {...param}
                        size="small"
                        label="แผนก"
                        required
                      />
                    )}
                  />
                )}
              />
            </div>
            <div className="">
              <Controller
                name="position"
                control={control}
                defaultValue={users?.position ? users?.position : null}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    id="free-solo-demo"
                    freeSolo
                    size="small"
                    options={position?.arr || []}
                    getOptionLabel={(option) =>
                      ` ตำแหน่ง ${option?.name || '-'}`
                    }
                    onChange={(e, newValue) => handleChangePositon(newValue)}
                    renderInput={(param) => (
                      <TextField {...param} size="small" label="ตำแหน่ง" />
                    )}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="level"
                control={control}
                defaultValue={users?.level ? users?.level : '-'}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    id="free-solo-demo"
                    freeSolo
                    options={level?.arr || []}
                    getOptionLabel={(option) => option?.name}
                    onChange={(e, newValue) => handleChangeLevel(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="ระดับพนักงาน"
                      />
                    )}
                  />
                )}
              />{' '}
            </div>
            {/* <div className="">
              <FormControl sx={{ minWidth: 120 }} fullWidth>
                <InputLabel id="type" size="small">
                  ประเภทพนักงาน
                </InputLabel>
                <Select
                  label="ประเภทพนักงาน"
                  value={selectTypeemployee}
                  required
                  fullWidth
                  size="small"
                >
                  <MenuItem value="พนักงานประจำ">พนักงานประจำ</MenuItem>
                  <MenuItem value="พนักงานชั่วคราว">พนักงานชั่วคราว</MenuItem>
                </Select>
              </FormControl>
            </div> */}

            <div>
              <Controller
                name="salary"
                defaultValue={0}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    type="number"
                    id="outlined-basic"
                    label="เงินเดือน"
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>

            <div>
              {' '}
              <Controller
                name="booking"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    id="outlined-basic"
                    label="เลขบัญชี"
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          </div>

          {/* {hideButton && (
            <div>
              <div className="py-2">
                <Button onClick={() => append({})} variant="contained" size="">
                  เพิ่ม
                </Button>
              </div>
              <div>
                {users?.certificate?.map((user, index) => (
                  <div>{user?.file?.name || ''}</div>
                ))}
              </div>
            </div>
          )} */}
          {/* <div>
            <div className="py-2">
              <Button onClick={() => append({})} variant="contained" size="">
                เพิ่ม
              </Button>
            </div>
            <div>
              {users?.certificate?.map((user, index) => (
                <div>{user?.file?.name || ''}</div>
              ))}
            </div>
          </div> */}
          <div className="font-bold py-2 ">หมายเหตุ</div>
          <Controller
            name="note"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                id="outlined-basic"
                label=""
                size="large"
                variant="outlined"
                fullWidth
              />
            )}
          />
          {console.log('fields', fields)}
        </div>
      )}
    </div>
  );
}
FormEmployee.propTypes = {
  Controller: PropTypes.object,
  control: PropTypes.object,
  department: PropTypes.object,
  handleChange: PropTypes.func,
  selectDepartment: PropTypes.object,
  date: PropTypes.object,
  setDate: PropTypes.object,
};
FormEmployee.defaultProps = {
  Controller: '',
  control: '',
  department: '',
  handleChange: '',
  selectDepartment: '',
  date: '',
  setDate: '',
};
