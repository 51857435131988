// import CalQuotaOption from './CalQuotaOption';
// import _ from 'lodash';

// export default function CalRemain(inputLeavetype, me) {
//   console.log('inputLeaveytpe', inputLeavetype);
//   if (1 > inputLeavetype?.options?.length > 0) {
//     return CalQuotaOption(me, inputLeavetype);
//   }
//   else if (inputLeavetype?.options?.length > 0) {

//   }
//   else {
//     return (
//       (inputLeavetype?.quantity || inputLeavetype?._id?.quantity) -
//       (inputLeavetype?.sum || 0)
//     );
//   }

import CalQuotaOption from './CalQuotaOption';
import _ from 'lodash';

export default function CalRemain(inputLeavetype, me, isHalfDay = false) {
  console.log('inputLeaveytpe', inputLeavetype);
  console.log('isHalfDay', isHalfDay);

  // ตรวจสอบว่ามี options และความยาวของ options มากกว่า 0
  if (inputLeavetype?.options?.length >= 1) {
    // เรียกใช้ CalQuotaOption สำหรับกรณีที่มี options
    return CalQuotaOption(me, inputLeavetype);
  }
  // กรณีไม่มี options หรือ options ว่าง
  else {
    // คำนวณโควต้าที่เหลือ
    const quantity =
      inputLeavetype?.quantity || inputLeavetype?._id?.quantity || 0;
    const used = inputLeavetype?.sum || 0;

    // ถ้าลาครึ่งวัน ให้ลดโควต้าที่ใช้ลงครึ่งหนึ่ง
    const remainingQuota = quantity - used;
    return isHalfDay ? remainingQuota - 0.5 : remainingQuota;
  }
}

// const leaveType = _.isObject(inputLeavetype?._id)
//   ? { ...inputLeavetype, ...inputLeavetype?._id }
//   : inputLeavetype;
// if (leaveType?.options?.length === 0) {
//   return leaveType?.remain ? leaveType?.remain : leaveType?.quantity;
// } else {
//   return CalQuotaOption(me, leaveType) - (leaveType?.sum || 0);
// }
//}
