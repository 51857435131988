/* eslint-disable object-curly-newline */
import { Button, Card, CardContent, Paper, Stack, Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useHistory } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PrintIcon from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TablePagination from '@mui/material/TablePagination';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import * as action from '../../redux/action';
import { ViewTitle } from '../../components/ViewTitle';
import CheckInlist from '../../components/Table/CheckInlist';
import _ from 'lodash';

export default function Employee({ title }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const leaveDoc = useSelector((state) => state.leaveDoc);
  const salary = useSelector((state) => state.Salary);
  const checkIn = useSelector((state) => state.checkIn);
  const setting = useSelector((state) => state.setting);
  const userId = localStorage.getItem('userId');
  const [value, setValue] = React.useState('1');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);
  const [name, setName] = useState('');
  // console.log("id", userId);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  console.log('Data', leaveDoc?.arr?.leaveTypeID);
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    dispatch(action.CheckIngetAll({ me: me.userData?._id, page, size }));
    dispatch(action.getAllSalary({ name: me?.userData?.name, page, size }));
    dispatch(action.getAllLeaveDoc({ name: me?.userData?.name, page, size }));
    dispatch(action.settingGetAll({}));
  }, [page, size]);

  const deleteLeaveDoc = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteLeaveDoc(id));
        dispatch(
          action.getAllLeaveDoc({ name: me?.userData?.name, page: 1, size: 5 })
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  const leaveDocAbsence = _.filter(leaveDoc?.quota?._id, {
    name: 'ลาป่วย',
  });
  console.log('leaveDocAbsence', leaveDoc?.quota?.[1]?._id);
  const handleEditData = () => {
    history.push(`/employee/editemployee/${userId}`);
  };
  const handleEditPSW = () => {
    history.push(`/employee/changepassword/${userId}`);
  };
  const handleViewLeaveDoc = (docId) => {
    history.push(`/employee/leaveform/${docId}`);
  };

  const handleOnleaveForm = () => {
    history.push('/employee/leaveform');
  };
  function disabledButton(status) {
    if (status === 'อนุมัติ') {
      return true;
    }
    return false;
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleRemoveNotify = async () => {
    await dispatch(
      // eslint-disable-next-line no-underscore-dangle
      action.putUser(me?.userData?._id, { line_token: null })
    );
    await dispatch(action.getMe());
  };

  const renderNofifyButton = () => {
    const lineNotifyAccessLink = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${setting?.lineNotify?.clientId}&redirect_uri=${setting?.lineNotify?.redirectURL}&scope=notify&state=${me?.userData?._id}`;

    if (me?.userData?.line_token && me?.userData?.line_token !== '') {
      return (
        <div className="flex  gap-2">
          <a href={lineNotifyAccessLink}>
            <Button variant="contained" color="warning">
              เปลี่ยนบัญชี
            </Button>
          </a>{' '}
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveNotify()}
          >
            ยกเลิก
          </Button>
        </div>
      );
    }
    return (
      <a href={lineNotifyAccessLink}>
        <Button variant="contained" color="success">
          รับการแจ้งเตือนผ่าน LINE
        </Button>
      </a>
    );
  };

  // console.log("leave", leaveDoc);
  const showLeaveDoc = () => (
    <div>
      <div className=" py-2 flex justify-between">
        <div>
          <h1 className="font-bold text-xl pb-4 text-blue-600">ประวัติการลา</h1>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <p className="font-bold" />
              </TableCell>
              <TableCell>
                {' '}
                <p className="font-bold">ชื่อ-นามสกุล</p>
              </TableCell>
              <TableCell>
                {' '}
                <p className="font-bold">ลา</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">วันที่ลา</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">สถานะ</p>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {leaveDoc?.arr?.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  ไม่มีรายการ
                </TableCell>
              </TableRow>
            ) : (
              leaveDoc?.arr?.map((leaveDocs) => (
                <TableRow key={leaveDocs.index}>
                  <TableCell component="th" scope="row" />
                  <TableCell>
                    {leaveDocs?.name} {leaveDocs?.surname}
                  </TableCell>
                  <TableCell>
                    {leaveDocs?.leaveTypeID?.name
                      ? leaveDocs?.leaveTypeID?.name
                      : leaveDocs?.leaveType}
                  </TableCell>
                  <TableCell>
                    {dayjs(leaveDocs?.dateStart).format('DD/MM/BBBB')}
                  </TableCell>
                  <TableCell>
                    {leaveDocs?.status}
                    {/* <div>
                      <div>
                        ผู้พิจารณาคนที่ 1 : {leaveDocs?.approve1?.status}
                      </div>
                      <div>
                        ผู้พิจารณาคนที่ 2 : {leaveDocs?.approve2?.status}
                      </div>
                    </div> */}
                  </TableCell>
                  <TableCell align="right">
                    <Stack spacing={1} direction="row" className="justify-end">
                      <Button
                        variant="contained"
                        startIcon={<FactCheckIcon />}
                        onClick={() => {
                          handleViewLeaveDoc(leaveDocs._id);
                        }}
                      >
                        ตรวจสอบ
                      </Button>

                      <Button
                        color="error"
                        variant="contained"
                        disabled={disabledButton(leaveDocs?.status)}
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          deleteLeaveDoc(leaveDocs?._id);
                        }}
                      >
                        ลบ
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={leaveDoc?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </TableContainer>
    </div>
  );
  const slipSalary = () => (
    <div className="">
      <div>
        <h1 className="font-bold text-xl pb-4 text-green-600">สลิปเงินเดือน</h1>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <p className="font-bold" />
              </TableCell>
              <TableCell>
                {' '}
                <p className="font-bold">เดือน </p>
              </TableCell>
              <TableCell>
                {' '}
                <p className="font-bold">ชื่อ</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">รายรับสุทธิ</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">ดำเนินการ</p>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {salary?.arr?.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  ไม่มีรายการ
                </TableCell>
              </TableRow>
            ) : (
              salary?.arr?.map((eachSalary) => (
                <TableRow key={eachSalary.index}>
                  <TableCell component="th" scope="row" />
                  <TableCell>
                    {dayjs(eachSalary?.date).format('MMMM BBBB')}
                  </TableCell>
                  <TableCell>{eachSalary?.user?.name}</TableCell>
                  <TableCell>{eachSalary?.sumSalary}</TableCell>
                  <TableCell>{eachSalary?.status}</TableCell>
                  <TableCell align="right">
                    <Stack spacing={1} direction="row" className="justify-end">
                      {eachSalary?.file?.url ? (
                        <a
                          href={eachSalary?.file?.url}
                          without
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<PrintIcon />}
                          >
                            พิมพ์
                          </Button>
                        </a>
                      ) : (
                        <Button
                          variant="contained"
                          color="success"
                          disabled
                          startIcon={<PrintIcon />}
                        >
                          พิมพ์
                        </Button>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={salary?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </TableContainer>
    </div>
  );
  const renderTable = () => (
    <CheckInlist
      checkIn={checkIn}
      name={name}
      page={page}
      size={size}
      setName={setName}
      setPage={setPage}
      setSize={setSize}
      disabledButton
    />
  );

  const renderTitle = () => <ViewTitle title={title} />;

  const renderProfile = () => (
    <div>
      <Paper>
        <Card>
          <CardContent>
            <div className="lg:pb-8 ">
              <div className=" mx-auto fl">
                <div className="flex justify-center py-4">
                  <Avatar
                    alt={me?.userData?.name}
                    src={`${me?.userData?.image?.url}`}
                    sx={{ minWidth: 200, minHeight: 200 }}
                  />
                </div>
              </div>

              <div>
                <div className="py-4 px-4  md:flex justify-center ">
                  <div className="md:w-1/3 md:flex justify-center ">
                    <div>
                      <h1 className="text-xl font-bold text-blue-600  ">
                        ข้อมูลส่วนตัว
                      </h1>
                      <div className="flex">
                        <div className="pt-4 pl-2">
                          <h1 className="font-bold text-gray-700">ชื่อ</h1>
                          <h1 className="font-bold text-gray-700">นามสกุล</h1>
                          <h1 className="font-bold text-gray-700 ">เบอร์โทร</h1>
                          <h1 className="font-bold text-gray-700">E-mail</h1>
                          <h1 className="font-bold text-gray-700">ที่อยู่</h1>
                          <h1 className="font-bold text-gray-700">เลขบัญชี</h1>
                        </div>
                        <div className=" pt-4 pl-2">
                          <h1>{me?.userData?.name}</h1>
                          <h1>{me?.userData?.surname}</h1>
                          <h1>{me?.userData?.tel}</h1>
                          <h1>{me?.userData?.email}</h1>
                          <h1>{me?.userData?.address}</h1>
                          <h1>{me?.userData?.booking}</h1>
                        </div>
                      </div>
                      <div className="py-4 ">
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              handleEditData();
                            }}
                          >
                            แก้ไขข้อมูล
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={() => {
                              handleEditPSW();
                            }}
                          >
                            เปลี่ยนรหัสผ่าน
                          </Button>
                        </Stack>
                      </div>
                      <b>การแจ้งเตือนผ่าน Line</b>
                      <div className="py-2">{renderNofifyButton()}</div>
                    </div>
                  </div>
                  <div className="md:w-1/3 md:flex justify-center ">
                    <div>
                      <h1 className="text-xl font-bold text-orange-600">
                        ข้อมูลการทำงาน
                      </h1>

                      <div className=" pt-4 ">
                        <p>
                          <b>รหัสพนักงาน</b> : {me?.userData?.employeeId || '-'}
                        </p>
                        <p>
                          <b>วันที่เริ่มงาน</b> :{' '}
                          {dayjs(me?.userData?.dateStart).format('DD/MM/BBBB')}
                        </p>
                        <p>
                          <b>แผนก</b> : {me?.userData?.department?.name}
                        </p>
                        <p>
                          <b>ตำแหน่ง</b> : {me?.userData?.position?.name || ''}
                        </p>
                        <p>
                          <b>เงินเดือน</b> : {me?.userData?.salary}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" border-t-4 border-green-500">
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 0,
                    borderColor: 'success',
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="ประวัติการลา" value="1" />
                    <Tab label="สลิปเงินเดือน" value="2" />
                    <Tab label="เวลาเข้า-ออกงาน" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">{showLeaveDoc()}</TabPanel>
                <TabPanel value="2">{slipSalary()}</TabPanel>
                <TabPanel value="3">{renderTable()}</TabPanel>
              </TabContext>
            </div>
          </CardContent>
        </Card>
      </Paper>
    </div>
  );

  if (
    leaveDoc.isLoading ||
    !leaveDoc.isCompleted ||
    !salary.isCompleted ||
    salary.isLoading ||
    checkIn.isLoading ||
    !checkIn.isCompleted
  ) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }

  if (
    !leaveDoc.isLoading ||
    leaveDoc.isCompleted ||
    !salary.isLoading ||
    salary.isCompleted ||
    !checkIn.isLoading ||
    checkIn.isCompleted
  ) {
    return (
      <div className="min-h-screen ">
        {renderTitle()}
        <div className="p-4">{renderProfile()}</div>
      </div>
    );
  }
}
Employee.propTypes = {
  title: PropTypes.string,
};

Employee.defaultProps = {
  title: '',
};
