/* eslint-disable no-param-reassign */
import { Button, Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import ImageUpload from '../../components/ImageUpload/ImageUpload';
import _ from 'lodash';
import * as action from '../../redux/action';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import FormEmployee from '../../components/Forms/FormEmmployee';

function EditEmployeeData({ title }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
  });
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState([]);
  const users = useSelector((state) => state.user);
  dayjs.extend(buddhistEra);

  async function onSubmit(data) {
    if (!_.isEmpty(selectedImage)) {
      data.image = {
        image: selectedImage[0]?.data_url,
        imageType: 'profile',
        alt: '',
      };
    }
    if (!_.isEmpty(selectedSignature)) {
      data.signature = {
        image: selectedSignature[0]?.data_url,
        imageType: 'sign',
        alt: '',
      };
    }
    const payload = {
      ...data,
      dateStart: date,
    };

    // console.log(payload);
    await dispatch(action.putUser(params.id, payload));
    history.goBack();
  }
  function getData() {
    dispatch(action.getOneUser(params.id));
  }
  useEffect(() => {
    getData();

    return () => {};
  }, []);

  useEffect(() => {
    // console.log("useeffect", users);
    setValue('name', users?.name);
    setValue('surname', users?.surname);
    setValue('nickname', users?.nickname);
    setValue('email', users?.email);
    setValue('address', users?.address);
    setValue('tel', users?.tel);
    setValue('personalID', users?.personalID);
    setValue('position', users?.position);
    setValue('salary', users?.salary);
    setValue('username', users?.username);
    setValue('booking', users?.booking);
    return () => {};
  }, [users]);

  // console.log(users);
  const handleEditUser = () => {};
  const [date, setDate] = React.useState(users?.dateStart);
  const renderTitle = () => <ViewTitle title={title} />;
  const renderBackButton = () => <BackButton />;
  const renderForm = () => (
    <FormEmployee Controller={Controller} control={control} edit={false} />
  );

  return (
    <div className="min-h-screen ">
      {renderTitle()}
      {renderBackButton()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2">
          <Card>
            <CardContent>
              <div className="">
                <div className="  w-full">
                  {renderForm()}
                  <div className="p-2 flex gap-4 ">
                    <div>
                      <ImageUpload
                        images={selectedImage}
                        title="อัปโหลดรูปโปรไฟล์"
                        maxNumber={1}
                        previewSize={200}
                        setImages={setSelectedImage}
                      />
                    </div>
                    <div>
                      <ImageUpload
                        title="เพิ่มลายเซ็น"
                        images={selectedSignature}
                        maxNumber={1}
                        previewSize={200}
                        setImages={setSelectedSignature}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-2 w-full" />
              </div>
              <div>
                <Button type="submit" size="large" variant="contained">
                  Save
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>{' '}
      </form>
    </div>
  );
}
export default EditEmployeeData;
