import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { Button, Card, CardContent, TextField } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import FormProduct from 'components/Forms/FormEditPurchaseProduct';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory, useParams } from 'react-router-dom';
////Modal
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ProductServe from '../../../components/Forms/FormProductArray';

function EditPurchase() {
  const [customerData, setCustomerData] = useState();
  const { handleSubmit, control, setValue, watch } = useForm({});
  const [date, setDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [reload, setReload] = useState(false);
  const [findName, setFindName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const history = useHistory();
  const params = useParams();
  dayjs.extend(buddhistEra);
  // console.log(dayjs(date).format(`BBMMDD`));
  // post data

  const purchase = useSelector((state) => state.purchase);
  // const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);
  const [productVat, setProductVat] = useState(false);
  const [basicVat, setBasicVat] = useState(false);

  //Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log("dataInvoice.product", purchase);
  ////////
  const onSubmitPurchase = async (data) => {
    if (productList.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          ...data,
          date,
          productVat,
          dueDate,
          prefix: 'PO' + dayjs(date).format(`BB`) + '-',
          product: data?.productArray,
          allPrice: allPrice(),
        };
        await dispatch(action.putPurchase(params.id, ProcessData));
        await dispatch(action.getAllPurchase({}));
        history.push('/purchase/order');
      } catch (error) {}
    }
  };
  //axios

  ///
  function getQtData(id) {
    dispatch(action.getOnePurchase(id));
  }

  function getProduct() {
    dispatch(action.getAllProduct());
  }
  useEffect(() => {
    // console.log(customer?.arr);
    getQtData(params.id);
    getProduct();
  }, []);

  useEffect(() => {
    setProductList(purchase?.product);
  }, [purchase]);
  // console.log("data", purchase);
  const ProductDelete = (index) => {
    productList.splice(index, 1);
    setProductList(productList);
    setReload(!reload);
  };
  useEffect(() => {
    setValue('businessName', purchase?.businessName);
    setValue('productArray', purchase?.product);
    setValue('date', purchase?.date);
    setValue('TaxId', purchase?.TaxId);
    setValue('tel', purchase?.tel);
    setValue('name', purchase?.name);
    setValue('address', purchase?.address);
    setValue('payment', purchase?.payment);
    setValue('credit', purchase?.credit);
    setValue('note', purchase?.note);
    setProductVat(purchase.productVat);
    return () => {};
  }, [purchase]);
  useEffect(() => {
    // console.log("product list", productList);
    return () => {};
  }, []);

  const sumPrice = () => {
    if (productList?.length === 0) {
      return 0;
    } else {
      const sumP = _.sumBy(productList, (each) => each?.price * each?.quantity);
      return sumP;
    }
  };
  const sumDiscount = () => {
    if (productList?.length === 0) {
      return 0;
    } else {
      const sumP = _.sumBy(productList, (each) => parseInt(each?.discount));
      return sumP;
    }
  };
  const Price = () => {
    if (productList?.length === 0) {
      return 0;
    } else {
      return sumPrice() - sumDiscount();
    }
  };
  const taxBasicVat = () => {
    if (basicVat) {
      return 0;
    }
    return Price() * 0.07;
  };
  const taxPrice = () => {
    if (productVat) {
      return 0;
    }
    return Price() * 0.03;
  };

  const allPrice = () => {
    return Price() + taxBasicVat() - taxPrice();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitPurchase)}>
      <div className="min-h-screen  pt-12 ">
        <div className=" ml-2 w-full rounded ">
          <h1 className="text-indigo-500  border-b-orange-500 text-4xl  font-bold ml-2">
            {' '}
            | ใบสั่งซื้อ
          </h1>
        </div>

        <div className="flex w-full pt-6 h-full">
          {' '}
          <div>
            <Card className="justify-center mt-2 ">
              <CardContent>
                <h1 className="pb-4"> ข้อมูลผู้ขาย</h1>
                <Controller
                  name="businessName"
                  control={control}
                  defaultValue={''}
                  rules={{ required: false }}
                  // defaultValue={{ businessname: "" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="ชื่อลูกค้า"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="TaxId"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="หมายเลขผู้เสียภาษี"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="tel"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-basic"
                      label="เบอร์โทร"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="address"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-multiline-basic"
                      label="ที่อยู่"
                      variant="outlined"
                      fullWidth
                      multiline
                      margin="normal"
                      rows={4}
                    />
                  )}
                />
              </CardContent>
            </Card>{' '}
          </div>
          <div className="่ w-full  pl-4 ">
            <div className="flex justify-end pb-4 ">
              <Stack spacing={2} direction="row">
                {' '}
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </Stack>
            </div>
            <div>
              <Card>
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่"
                      value={purchase?.date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่วันที่กำหนดส่ง"
                      value={purchase?.dueDate}
                      onChange={(newValue) => {
                        setDueDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <Controller
                    name="credit"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="จำนวนวันเครดิต"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="payment"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="วิธีการชำระเงิน"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </CardContent>
              </Card>
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  position: 'absolute',
                }}
              >
                <Typography id="modal-modal-title">
                  <FormProduct
                    setProductList={setProductList}
                    setReload={setReload}
                    setOpen={setOpen}
                    productList={productList}
                    reload={reload}
                    product={product}
                  ></FormProduct>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div>
          <div className="mt-2">
            {console.log('productVatdjj', productVat)}
            <ProductServe
              control={control}
              setProductList={setProductList}
              setValue={setValue}
              edit={true}
              watch={watch}
              setProductVat={setProductVat}
              productVat={productVat}
              statusHide={true}
              statusPickUp={true}
              basicVat={basicVat}
              setBasicVat={setBasicVat}
            />
          </div>
          <Card>
            <CardContent>
              <div className="flex w-full ">
                <div className="w-1/2">
                  {' '}
                  <Controller
                    name="note"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="หมายเหตุ"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>

                <div className="flex justify-between  w-1/2 ml-2  ">
                  <div>
                    <div>รวมเงิน </div>
                    <div>ส่วนลดการค้า</div>
                    <div>เงินหลังหักส่วนลด</div>
                    <div>หัก ณ ที่จ่าย 3%</div>
                    <div>VAT 7%</div>
                    <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                  </div>
                  <div className="text-right">
                    <div>
                      {sumPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {sumDiscount()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {Price()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                    <div>
                      {taxPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                    <div>
                      {taxBasicVat()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div className="font-bold ">
                      {allPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </form>
  );
}
export default EditPurchase;
